import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { UiConfig } from '@models/config';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: false,
})
export class NavComponent implements OnInit {
    @Input() navLabel: string;
    @Input() showNavLinks = true;

    userName: string;
    customer: string;

    email: string;
    isRaUser: boolean;

    showInternal = false;

    config: UiConfig;

    urlStore = UrlStore;

    featureNotPurchased = 'You have not purchased this feature';
    featureNotEnabled = 'This feature not currently available';
    dashboardRoute: string = null;
    pricingHubRoute: string = null;
    bulkRatesRoute: string = null;
    rateGeneratorRoute: string = null;
    advertiserInsightsRoute: string = null;
    inventoryInsightsRoute: string = null;
    politicalLurRoute: string = null;
    ratePerformanceRoute: string = null;
    updateStatusRoute: string = null;
    removeChannelsRoute: string = null;
    roleConfigRoute: string = null;
    roleConfigRouteV2: string = null;
    configRoute: string = null;
    editWhiteListRoute: string = null;
    configurationSettings: string = null;
    userSettingsRoute: string = null;
    userManagementRoute: string = null;
    userActivityReportRoute: string = null;
    aidaRoute: string = null;
    aidaChatRoute: string = null;
    emailedReportRoute: string = null;
    politicalDaypartsRoute: string = null;
    aidaAdminRoute: string = null;
    icons = {
        dashboard: { iconClass: 'fa-chess', label: 'Optics Dashboard' },
        pricingHub: { iconClass: 'fa-address-card', label: 'Pricing Hub' },
        bulkRates: { iconClass: 'fa-cloud-download-alt', label: 'Bulk Rates Export' },
        rateGenerator: { iconClass: 'fa-charging-station', label: 'Rate Generator' },
        advertiserInsights: { iconClass: 'fa-search-dollar', label: 'Advertiser Insights' },
        inventoryInsights: { iconClass: 'fa-binoculars', label: 'Inventory Insights' },
        aida: { iconClass: 'fa-address-card', label: 'Aida' },
        aidaChat: { iconClass: 'fa-comments', label: 'Aida Chat' },
        politicalLUR: { iconClass: 'fa-poll', label: 'Political LUR' },
        ratePerformance: { iconClass: 'fa-wave-square', label: 'Rate Performance' },
        mainMenu: { iconClass: null, label: null },
    };

    constructor(public authSvc: AuthService, private router: Router) {
    }

    ngOnInit() {
        this.config = this.authSvc.getConfig();
        this.email = localStorage.getItem('email');
        this.isRaUser = this.authSvc.isRaUser();
        this.userName = this.config?.userName || '';
        this.showInternal = this.isNonProd() && this.isRaAdmin();
        this.customer = this.config?.allowedIds?.split(',').length > 1 && this.isRaAdmin() ? this.config.customerId : null;
        this.setRoutes();
        this.router.events.subscribe((event) => {
            this.mainMenuIconAndName();
        });

    }

    setRoutes() {
        this.setMainMenuRoutes();
        this.setRAdminRoutes();
        this.setUserSettingRoutes();
    }

    hasAdminAccess() {
        return this.config?.roleCapability?.rate_optics?.admin?.access;
    }

    isNonProd() {
        return window.location.hostname !== 'rateoptics.io';
    }

    isRaAdmin() {
        return ['RA Admin', 'RA Super Admin'].includes(this.config?.userRole);
    }

    hasAdminRouteAccess() {
        return this.isRaAdmin() && this.hasAdminAccess();
    }

    hasUserManagementRouteAccess() {
        return this.config?.roleCapability?.rate_optics?.user_management?.access || this.authSvc.isRaUser();
    }

    setRAdminRoutes() {
        const adminRoutes = this.urlStore.ui.admin;
        this.updateStatusRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.updateStatus);
        this.removeChannelsRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.removeChannels);
        this.roleConfigRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.roleConfig);
        this.roleConfigRouteV2 = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.roleConfigV2);
        this.configRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.config);
        this.editWhiteListRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.editWhitelist);
        this.politicalDaypartsRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.politicalDayparts);
        this.aidaAdminRoute = this.setRoute(this.hasAdminRouteAccess(), adminRoutes.aida);
    }

    setMainMenuRoutes() {
        const rateOpticsCapabilities = this.config?.roleCapability?.rate_optics;
        const rateOpticsRoutes = this.urlStore.ui.rateOptics;
        this.dashboardRoute = this.setRoute(rateOpticsCapabilities?.dashboard?.access, rateOpticsRoutes.dashboard);
        this.pricingHubRoute = this.setRoute(rateOpticsCapabilities?.rate_card?.access, rateOpticsRoutes.rateCardV2);
        this.bulkRatesRoute = this.setRoute(rateOpticsCapabilities?.bulk_rates?.access, rateOpticsRoutes.bulkRates);
        this.rateGeneratorRoute = this.setRoute(rateOpticsCapabilities?.rate_generator?.access, rateOpticsRoutes.rateGenerator);
        this.advertiserInsightsRoute = this.setRoute(rateOpticsCapabilities?.advertiser_insights?.access,
                                                     rateOpticsRoutes.advertiserInsights);
        this.inventoryInsightsRoute = this.setRoute(rateOpticsCapabilities?.inventory_insights?.access, rateOpticsRoutes.inventoryInsights);
        this.politicalLurRoute = this.setRoute(rateOpticsCapabilities?.political_lur?.access, rateOpticsRoutes.politicalLUR);
        this.ratePerformanceRoute = this.setRoute(rateOpticsCapabilities?.rate_performance?.access, rateOpticsRoutes.ratePerformance);
        this.aidaRoute = this.setRoute(rateOpticsCapabilities?.aida?.access, rateOpticsRoutes.aida);
        this.aidaChatRoute = this.setRoute(rateOpticsCapabilities?.aida?.aida_chat, rateOpticsRoutes.aidaChat);
    }

    setUserSettingRoutes() {
        const rateOpticsCapabilities = this.config?.roleCapability?.rate_optics;
        const adminRoutes = this.urlStore.ui.admin;
        this.configurationSettings = this.setRoute(this.config?.roleCapability?.rate_optics?.admin?.access, adminRoutes.base);
        this.userSettingsRoute = this.setRoute(rateOpticsCapabilities?.rate_card?.access, adminRoutes.rateOpticsSettings);
        this.userManagementRoute = this.setRoute(this.hasUserManagementRouteAccess(), adminRoutes.rateOpticsUserManagement);
        this.emailedReportRoute = this.setRoute(this.hasUserManagementRouteAccess(), adminRoutes.emailedReport);
        this.userActivityReportRoute = this.setRoute(rateOpticsCapabilities?.user_activity_report?.access, adminRoutes.userActivityReport);
    }

    setRoute(access: boolean, url: string) {
        return access ? '/' + url : null;
    }

    mainMenuIconAndName() {
        const url = this.router.url;
        let icons = { iconClass: null, label: null };
        if (url.includes(this.pricingHubRoute)) {
            icons = this.icons.pricingHub;
        } else if (url.includes(this.dashboardRoute)) {
            icons = this.icons.dashboard;
        } else if (url.includes(this.bulkRatesRoute)) {
            icons = this.icons.bulkRates;
        } else if (url.includes(this.inventoryInsightsRoute)) {
            icons = this.icons.inventoryInsights;
        } else if (url.includes(this.advertiserInsightsRoute)) {
            icons = this.icons.advertiserInsights;
        } else if (url.includes(this.politicalLurRoute)) {
            icons = this.icons.politicalLUR;
        } else if (url.includes(this.aidaRoute)) {
            icons = this.icons.aida;
        } else if (url.includes(this.ratePerformanceRoute)) {
            icons = this.icons.ratePerformance;
        } else if (url.includes(this.rateGeneratorRoute)) {
            icons = this.icons.rateGenerator;
        }
        this.icons.mainMenu = icons;
    }

}
